import * as THREE from 'three'
import GUI from 'lil-gui'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton'

const canvas = document.querySelector('canvas.webgl')

const scene = new THREE.Scene()

const gui = new GUI()

const textureLoader = new THREE.TextureLoader()

//const doorColorTexture = textureLoader.load('./textures/door/color.jpg')
const doorColorTexture = textureLoader.load('./textures/minecraft/moss_block.png')
doorColorTexture.magFilter = THREE.NearestFilter
doorColorTexture.minFilter = THREE.NearestFilter
doorColorTexture.colorSpace = THREE.SRGBColorSpace

var debug = {
    wireframe: false
}

gui.add(debug, "wireframe")

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () =>
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    }
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

//Object
const geometry = new THREE.BoxGeometry(1, 1, 1)
const material = new THREE.MeshBasicMaterial({ map: doorColorTexture })
//const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const mesh = new THREE.Mesh(geometry, material)

scene.add(mesh)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    xr: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.xr.enabled = true

document.body.appendChild( VRButton.createButton( renderer ) );

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = (delta) =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    material.wireframe = debug.wireframe
    mesh.rotation.x += (delta * 0.000005)
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    //window.requestAnimationFrame(tick)
}

renderer.setAnimationLoop(tick);

//tick()
